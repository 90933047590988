/* @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

  
   html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #F1F1F1;
  }

  #structure {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
  }
    
  #main_content {
    overflow: auto;
  }
  
  .box {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    color: #6b6b6b;
    font-weight: 300;
    position: relative;
    box-shadow: 0 0 10px #c2c2c2;
    display: flex;
    flex-direction: column;
  }
  
  .statesContainer {
    padding: 0 20px;
  }
  
  div#state_selector {
    padding: 0;
  }
  
  div#state_selector h1 {
    text-align: center;
    padding: 0;
    margin: 0;
    background: #DFAB00;
    color: #fff;
    padding: 13px;
  }
  
  h1.iconTitle {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 400;
    justify-content: center;
    gap: 10px;
  }
  
  .stateButton:last-child {
    border-bottom: none !important;
  }
  
  .scrollView {
    overflow: auto;
    height: 100%;
  }
  
  .loaderHide {
    opacity: 0;
    pointer-events: all;
  }
  
  div#loader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 9999;
    transition: 0.5s;
    pointer-events: none;
  }
  
  div#topBar {
    background: #393838;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .content {
    padding: 20px;
    height: 100%;
    flex: 1;
    background: #F1F1F1;
    margin: 0;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  
  h2 {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
  }
  
  .promotional_carousel a {
    display: block;
  }
  
  .promotional_carousel img {
    border-radius: 5px;
  }
  
  .product_card img {
    width: 100%;
    border-radius: 5px 5px 0px 0px;
  }
  
  .product_card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #c2c2c2;
    display: flex;
    flex-direction: column;
  }
  
  .product_card_info {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .product_card_info_left {
    display: flex;
    flex-direction: column;
  }
  
  .product_card_info_left p {
    margin: 0;
    font-size: 14px;
  }
  
  .product_card_name {
    color: #DFAB00;
  }
  
  
  .content_wrapper {
    width: 100%;
    height: 100%;
    flex: 1;
    background: #F1F1F1;
    font-family: var(--main-font);
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .content_wrapper_container {
    padding: 20px;
    height: 100%;
    flex: 1;
    background: #F1F1F1;
    margin: 0;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .product_detail_container {
    padding: 20px;
    height: 100%;
    font-family: var(--main-font);
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .product_detail_container img {
    max-width: 100%;
  }
  
  .product_detail {
    background: #fff;
    border-radius: 10px;
    overflow: auto;
    font-weight: 300;
    position: relative;
    box-shadow: 0 0 10px #c2c2c2;
    display: flex;
    flex-direction: column;
  }
  
  .product_detail_header {
    display: flex;
    padding: 10px 15px;
    border-bottom: solid 1px #dcdcdc;
    gap: 10px;
  }
  
  .product_detail_header p {
    margin: 0;
    font-size: 14px;
  }
  
  .product_detail_content {
    padding: 20px;
  }
  
  .product_detail_content h2 {
    font-size: 15px;
    color: #000;
  }
  
  .product_description {
    font-size: 14px;
  }
  
  .product_description {
    font-size: 14px;
  }
  
  
  .product_button {
    background: #DFAB00;
    color: #fff;
    width: 100%;
    display: flex;
    border-radius: 20px;
    text-align: center;
  }
  
  .pdf_button {
    background: #3a3a3a;
  }
  
  .product_button span{
    white-space: nowrap;
  }
  
  .product_button div {
    border: dashed 2px;
    padding: 5px 15px;
    margin: 5px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    justify-content: center;
  }
  
  
  .product_buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .history_card {
    box-shadow: 0 0 10px #c2c2c2;
    background: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    color: #6E6D6D;
  }

  .history_card_info {
    width: 100%;
}
  
  .history_card_info p {
    margin: 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  
  span.history_price {
    color: #DFAB00;
    font-weight: 700;
  }
  
  span.history_time {
    font-weight: 700;
  }
  
  #details_container {
    justify-content: center;
  }
  
  .details_container_box {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    font-weight: 300;
    position: relative;
    box-shadow: 0 0 10px #c2c2c2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .details_container_box h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  
  .details_container_box p {
    margin: 0;
  }
  
  .icon_container {
    margin-right: 10px;
  }
  
  .slider_container {
    padding: 6px 0;
  }
  
  .category_icon{
    border-radius: 5px;
    box-shadow: 0 0 6px #00000016;
  }

  .empty_message {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    align-items: center;
    font-weight: 300;
    color: #9a9a9a;
}